const menuBtn = document.querySelector('.mob-menu__link-sub')
menuBtn?.addEventListener('click', (e)=>{
  e.preventDefault()
  const menuContent = document.querySelector('.mob-menu__sublist')
  if(menuContent.classList.contains('active')){
    menuContent.classList.remove('active')
  }else{
    menuContent.classList.add('active')
  }
})
