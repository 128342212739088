import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

const slider1 = document.querySelector('.hero-slider');

let mySwiper1 = new Swiper(slider1, {
	slidesPerView: 1,
	spaceBetween: 10,
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	autoplay: {
		delay: 5000,
	},
	navigation: {
		nextEl: '.hero-button-next',
		prevEl: '.hero-button-prev',
	},

})

const slider2 = document.querySelector('.brands-slider');

let mySwiper2 = new Swiper(slider2, {
	slidesPerView: 2,
	spaceBetween: 20,
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	autoplay: {
		delay: 5000,
	},
	navigation: {
		nextEl: '.brands-button-next',
		prevEl: '.brands-button-prev',
	},
	breakpoints: {
		1025: {
			slidesPerView: 3,
      spaceBetween: 30,
		},
	}
})
